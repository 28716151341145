<template>
	<div class="page">
		<fieldset class="search-box">
			<legend>查询条件</legend>
			<el-input size="small" style="width: 300px;margin-right: 20px;" placeholder="请输入卡号" v-model="searchStr">
				<template #prepend>卡号</template>
			</el-input>
			<el-button @click="search" size="small">搜索</el-button>
			<el-button @click="dialog.add = true" type="primary" size="small">添加卡号</el-button>
			<el-button @click="dialog.upload = true" type="primary" size="small">xlxs批量导入</el-button>
		</fieldset>

		<div ref="tableBox" style="margin-top: 10px;flex-grow: 1; background-color: white;padding-bottom: 20px;">
			<el-table size="small" header-cell-class-name="tableHeader" :data="list" border :max-height="tableHeight"
				style="width: 100%;">
				<el-table-column prop="icc_id" label="iccid" width="300">
				</el-table-column>
				<el-table-column prop="upload_time" label="导入日期" width="200">
				</el-table-column>
				<el-table-column fixed="right" label="操作">
					<template #default="scope">
						<el-dropdown trigger="click" @command="handleCommand">
							<span class="el-dropdown-link">
								<el-button type="text" size="small">操作
								</el-button>
							</span>
							<template #dropdown>
								<el-dropdown-menu>
									<el-dropdown-item :command="{api:1,data:scope.row}">物联卡指定日期用量日志查询</el-dropdown-item>
									<el-dropdown-item :command="{api:2,data:scope.row}">物联卡月流量日志</el-dropdown-item>
									<el-dropdown-item :command="{api:5,data:scope.row}">物联卡信息</el-dropdown-item>
									<el-dropdown-item :command="{api:9,data:scope.row}">获取物联网卡充值套餐</el-dropdown-item>
								</el-dropdown-menu>
							</template>
						</el-dropdown>
					</template>
				</el-table-column>
			</el-table>

			<div style="margin-top: 10px; float: right;">
				<el-pagination v-model:currentPage="currentPage" :page-sizes="[30]" :page-size="30"
					layout="total, sizes, prev, pager, next, jumper" :total="count" @size-change="handleSizeChange"
					@current-change="handleCurrentChange">
				</el-pagination>
			</div>


			<el-dialog v-model="dialog.upload" title="批量导入" width="50%">
				<el-form ref="form" :model="form" label-width="100px">
					<el-form-item label="选择卡商">
						<el-select v-model="form.kaShang" placeholder="Select">
							<el-option v-for="item in kaShangList" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item v-if="form.kaShang" label="上传文件">
						<el-upload :data="{'KID':form.kaShang}" :file-list="fileList" :on-success="uploadFinish"
							class="upload-demo" drag action="http://127.0.0.1:8080/file/uploadXlsx" multiple>
							<i class="el-icon-upload"></i>
							<div class="el-upload__text">
								将文件拖到此处 <em>或点击选择文件</em>
							</div>
						</el-upload>
					</el-form-item>
				</el-form>
			</el-dialog>

			<el-dialog v-model="dialog.add" width="50%">
				<el-card>
					<el-form v-model="addCardInfo" label-width="80px">
						<el-form-item label="卡商">
							<el-select v-model="addCardInfo.kaShang" placeholder="Select">
								<el-option v-for="item in kaShangList" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="iccid">
							<el-input v-model="addCardInfo.iccid"></el-input>
						</el-form-item>
					</el-form>
					<el-button size="small" @click="addCardItem" type="primary">添加</el-button>
				</el-card>
			</el-dialog>

			<el-dialog v-model="dialog.cardInfo" title="卡详情" width="70%">
				<el-descriptions title="卡详情" border>
					<el-descriptions-item label="msisdn">{{cardInfo.msisdn}}</el-descriptions-item>
					<el-descriptions-item label="iccid">{{cardInfo.iccid}}</el-descriptions-item>
					<el-descriptions-item label="imsi">{{cardInfo.imsi}}</el-descriptions-item>
					<el-descriptions-item label="运营商">
						<el-tag size="small">{{cardInfo.carrier}}</el-tag>
					</el-descriptions-item>
					<el-descriptions-item label="套餐大小">{{cardInfo.data_plan}}</el-descriptions-item>
					<el-descriptions-item label="当月用量,单位M">{{cardInfo.data_usage}}</el-descriptions-item>
					<el-descriptions-item label="卡状态">{{cardInfo.account_status}}</el-descriptions-item>
					<el-descriptions-item label="计费开始日期">{{formatDate(cardInfo.start_date)}}</el-descriptions-item>
					<el-descriptions-item label="计费结束日期">{{formatDate(cardInfo.expiry_date)}}</el-descriptions-item>
					<el-descriptions-item label="激活状态">{{cardInfo.active == 0 ? '未激活' : '激活'}}</el-descriptions-item>
					<el-descriptions-item label="激活日期">{{formatDate(cardInfo.start_date)}}</el-descriptions-item>
					<el-descriptions-item label="剩余流量">{{cardInfo.data_balance}}</el-descriptions-item>
					<el-descriptions-item label="出库日期">{{formatDate(cardInfo.outbound_date)}}</el-descriptions-item>
					<el-descriptions-item label="是否支持短信">{{cardInfo.support_sms}}</el-descriptions-item>
				</el-descriptions>
			</el-dialog>

			<el-dialog v-model="dialog.taocanInfo" title="套餐详情" width="50%">
				<el-descriptions border>
					<el-descriptions-item label="充值套餐id">{{taocan.product_id}}</el-descriptions-item>
					<el-descriptions-item label="充值月份">{{taocan.added_month}}</el-descriptions-item>
					<el-descriptions-item label="套餐名称">{{taocan.product_name}}</el-descriptions-item>
					<el-descriptions-item label="充值价格，单位分">{{taocan.price}}</el-descriptions-item>
				</el-descriptions>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import {
		kaHaoList,
		kaHaoCount,
		getKaShangList,
		usagelog,
		fetch,
		monthlyusagelog,
		addCardItem
	} from '../../api/api.js'
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import {
		cardInfo
	} from '../../api/api.js'
	export default {
		data() {
			return {
				addAllDialog: false,
				dialog: {
					add: false,
					upload: false,
					cardInfo: false,
					taocanInfo: false
				},
				taocan: {},
				start: 0,
				tableHeight: 100,
				list: [],
				currentPage: 1,
				count: 0,
				kaShangList: [],
				form: {},
				cardInfo: {},
				addCardInfo: {},
				searchStr: ""
			}
		},

		mounted() {
			this.getList()
			this.tableInit()
			var that = this
			kaHaoCount().then(res => {
				that.count = res.data.data
			})

			getKaShangList().then(res => {
				let arr = []
				let data = res.data.data
				let len = data.length
				for (let i = 0; i < len; i++) {
					arr.push({
						value: data[i].id,
						label: data[i].title,
					})
				}
				that.kaShangList = arr
			})
		},

		methods: {
			//表格高度自适应
			tableInit: function() {
				var that = this
				let tableBox = this.$refs.tableBox
				this.tableHeight = tableBox.offsetHeight - 60
				setTimeout(function() {
					tableBox.children[0].style.maxHeight = that.tableHeight + "px"
				}, 0)
			},


			uploadFinish: function(response) {
				console.log(response)
				this.getList()
			},

			addCardItem: function() {
				if (!this.addCardInfo.kaShang) {
					ElMessage({
						type: 'error',
						message: '请选择卡商',
					})
					return
				}
				var that = this
				addCardItem({
					icc_id: this.addCardInfo.iccid,
					k_id: this.addCardInfo.kaShang
				}).then(() => {
					ElMessage({
						type: 'success',
						message: '添加成功',
					})
					that.list()
					that.dialog.add = false
				})
			},

			getList: function() {
				var that = this
				kaHaoList({
					start: that.start
				}).then(res => {
					that.list = res.data.data
				})
			},

			handleCurrentChange: function(data) {
				var that = this
				this.start = data
				kaHaoList({
					start: that.start
				}).then(res => {
					that.list = res.data.data
				})
			},

			formatDate: function(date) {
				date = new Date(date * 1000);
				var YY = date.getFullYear() + '-';
				var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
				var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
				return YY + MM + DD;
			},

			search: function() {
				var that = this
				kaHaoList({
					start: that.start,
					iccId: that.searchStr
				}).then(res => {
					that.list = res.data.data
				})
			},

			handleCommand: function(data) {
				var that = this
				switch (data.api) {
					case 1:
						ElMessageBox.prompt('请输入查询日期，格式为YYYYMMDD', '查询日期', {
								confirmButtonText: '提交',
								cancelButtonText: '取消',
							})
							.then(({
								value
							}) => {
								usagelog({
									iccId: data.data.icc_id,
									dataTime: value
								}).then(res => {
									var pattern = /(\d{4})(\d{2})(\d{2})/;
									var formatedDate1 = value.replace(pattern, '$1/$2/$3');
									var ddate1 = new Date(formatedDate1);
									var Y = ddate1.getFullYear();
									var M = ddate1.getMonth() + 1;
									var D = ddate1.getDate();
									var times = Y + (M < 10 ? "-0" : "-") + M + (D < 10 ? "-0" : "-") + D;
									ElMessageBox.alert(`该卡号在${times}的流量使用为${res.data.data[0].data_usage}M`,
										'查询结果', {
											confirmButtonText: 'OK',
										})
								}).catch(() => {
									ElMessage({
										type: 'error',
										message: '查询失败',
									})
								})
							})
						break
					case 2:
						ElMessageBox.prompt('请输入查询日期，格式为YYYYMM', '查询日期', {
								confirmButtonText: '提交',
								cancelButtonText: '取消',
							})
							.then(({
								value
							}) => {
								monthlyusagelog({
									iccId: data.data.icc_id,
									dataTime: value
								}).then(res => {
									var pattern = /(\d{4})(\d{2})/;
									var formatedDate1 = value.replace(pattern, '$1/$2');
									var ddate1 = new Date(formatedDate1);
									var Y = ddate1.getFullYear();
									var M = ddate1.getMonth() + 1;
									var times = Y + (M < 10 ? "-0" : "-") + M;
									ElMessageBox.alert(
										`该卡号在${times}当月的流量使用为${res.data.data[0].data_usage}M`,
										'查询结果', {
											confirmButtonText: 'OK',
										})
								}).catch(() => {
									ElMessage({
										type: 'error',
										message: '查询失败',
									})
								})
							})
						break
					case 5:
						that.cardInfo = {}
						cardInfo({
							iccId: data.data.icc_id,
						}).then(res => {
							that.cardInfo = res.data.data
							switch (that.cardInfo.account_status) {
								case 0:
									that.cardInfo.account_status = "未知"
									break
								case 1:
									that.cardInfo.account_status = "测试期"
									break
								case 2:
									that.cardInfo.account_status = "沉默期"
									break
								case 3:
									that.cardInfo.account_status = "使用中"
									break
								case 4:
									that.cardInfo.account_status = "停机"
									break
								case 5:
									that.cardInfo.account_status = "停机保号"
									break
								case 6:
									that.cardInfo.account_status = "预销号"
									break
								case 7:
									that.cardInfo.account_status = "销号"
									break
							}
							that.dialog.cardInfo = true
						}).catch(() => {
							ElMessage({
								type: 'error',
								message: '查询失败',
							})
						})
						break
					case 9:
						that.taocan = {}
						fetch({
							iccId: data.data.icc_id,
						}).then(res => {
							that.taocan = res.data.data.products[0]
							that.dialog.taocanInfo = true
						}).catch(() => {
							ElMessage({
								type: 'error',
								message: '查询失败',
							})
						})
						break
				}
			},


		}
	}
</script>

<style scoped="scoped">
	.page {
		background-color: white;
		padding: 10px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.search-box {
		margin: 0;
		border: 1px solid #e6e6e6;
		padding: 10px 20px 20px 20px;
		color: #6b6b6b;
	}
</style>
